export enum Roles {
  admin = 'admin',
  community_admin = 'community_admin',
  content = 'content',
  member = 'member',
  super_admin = 'super-admin',
}

export const DATETIME_FORMAT = 'MMM dd, yyyy HH:mm'
export const DATEPICKER_DATE_FORMAT = 'yyyy-MM-dd'
export const MYSQL_MEDIUM_TEXT_LENGTH = 16777215
