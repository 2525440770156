// parallel to ChatCompletionRequestMessage, but without openai dependency
export interface ChatPrompt {
  content: string,
  role: string,
}

export interface AnalyzePayload {
  content: string,
  title: string,
  url: string,
}

export enum AnalyzeType {
  RSS = 'RSS Feed',
  URL = 'article',
}

export interface AnalyzeOptions {
  includeSummary?: boolean,
  source?: string,
  topicCount?: number,
  type: AnalyzeType,
  wordCount?: number,
}

export interface Keyword {
  id?: string | number,
  source: string,
  text: string,
  weight?: number,
}

export interface AnalyzeResponse {
  error?: string,
  categories?: Keyword[],
  prompts?: ChatPrompt,
  summary: string,
  topics: Keyword[],
}

export interface ChatPayload extends AnalyzePayload {
  emojis: number,
  language: string,
  length: number,
  prompt: string,
  promptType: string,
  tone: string,
  topics?: string[],
}

export interface ChatResponse {
  done?: boolean
  error?: string,
  message: string,
  prompts?: ChatPrompt[],
  session_id?: string,
}

export type ChatCallback = (chunk: ChatResponse) => void

export interface AnimatePayload {
  imageDuration: number,
  imageHeight: number,
  imageWidth: number,
  videoUrl: string,
}

export interface AnimateResponse {
  imageUrl: string,
}

export enum GenerationType {
  SUMMARY = 'summary',
  TEXT = 'text',
}
